import React from "react";

import { Context } from "@reactivated";

import { RichText } from "@thelabnyc/thelabui/src/components/RichText";

export const GlobalFooter = () => {
    const context = React.useContext(Context);
    return (
        <footer className="container">
            <div className="row">
                <div className="col-sm-12">
                    <ul className="list-inline">
                        <li className="footer__icon">
                            <a
                                href="https://github.com/wagtail/wagtail"
                                target="_blank"
                                rel="noreferrer"
                                aria-label="View Wagtail's source code on GitHub"
                            >
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="fa fa-github fa-stack-1x fa-inverse"></i>
                                </span>
                            </a>
                        </li>
                        <li className="footer__icon">
                            <a
                                href="https://twitter.com/wagtailcms"
                                target="_blank"
                                rel="noreferrer"
                                aria-label="Wagtail's official Twitter account"
                            >
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="fa fa-twitter fa-stack-1x fa-inverse"></i>
                                </span>
                            </a>
                        </li>
                        <li className="footer__icon">
                            <a
                                href="https://wagtail.org/"
                                target="_blank"
                                rel="noreferrer"
                                aria-label="Wagtail's official website"
                            >
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="fa fa-link fa-stack-1x fa-inverse"></i>
                                </span>
                            </a>
                        </li>
                    </ul>
                    <RichText value={context.footer.body} />
                </div>
            </div>
        </footer>
    );
};
