import React from "react";

import { templates } from "@reactivated";

import { BlogListingCard } from "../components/BlogListingCard";
import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.RecipeIndexPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <div className="container">
                <div className="blog-list">
                    {props.page.recipes.map((recipe) => (
                        <BlogListingCard key={recipe.id} {...recipe} />
                    ))}
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
