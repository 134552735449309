import React from "react";

import { templates } from "@reactivated";

import { BaseToolbox } from "../components/BaseToolbox";
import { HeaderHero } from "../components/HeaderHero";
import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.StandardPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <HeaderHero {...props.page} />
            <div className="container bread-detail">
                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-7">
                            <div className="row">
                                {props.page.introduction && (
                                    <p className="bread-detail__introduction">
                                        {props.page.introduction}
                                    </p>
                                )}
                                <BaseToolbox value={props.page.body.value} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
