import React from "react";

import { templates } from "@reactivated";

import { BaseToolbox } from "../components/BaseToolbox";
import { HeaderHero } from "../components/HeaderHero";
import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.LocationPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <HeaderHero {...props.page} />
            <div className="container bread-detail">
                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-7">
                            <div className="row">
                                {props.page.introduction && (
                                    <p className="bread-detail__introduction">
                                        {props.page.introduction}
                                    </p>
                                )}
                                <div className="hidden-md-down">
                                    <BaseToolbox
                                        value={props.page.body.value}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-md-offset-1">
                            <div className="row">
                                <div className="bread-detail__meta">
                                    <p className="location__meta-title">
                                        Operating Status
                                    </p>
                                    {props.page.is_open
                                        ? "This location is currently open."
                                        : "Sorry, this location is currently closed."}

                                    <p className="location__meta-title">
                                        Address
                                    </p>
                                    <address>{props.page.address}</address>

                                    {props.page.operating_hours && (
                                        <>
                                            <p className="location__meta-title">
                                                Opening hours
                                            </p>
                                            {props.page.operating_hours.map(
                                                (period) => (
                                                    <time
                                                        key={period.summary}
                                                        itemProp="openingHours"
                                                        dateTime={
                                                            period.summary
                                                        }
                                                        className="location__time"
                                                    >
                                                        <span className="location__day">
                                                            {period.day}
                                                        </span>
                                                        : &nbsp;
                                                        <span className="location__hours">
                                                            {period.closed ? (
                                                                "Closed"
                                                            ) : (
                                                                <>
                                                                    {
                                                                        period.opening_time
                                                                    }{" "}
                                                                    -{" "}
                                                                    {
                                                                        period.closing_time
                                                                    }
                                                                </>
                                                            )}
                                                        </span>
                                                    </time>
                                                ),
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className="row hidden-md-up">
                                <BaseToolbox value={props.page.body.value} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
