import React from "react";

import { RecipeStepBlockList as Value } from "@reactivated";

import { RecipeStepBlock } from "../RecipeStepBlock";

interface Props {
    value: Value;
}

export const RecipeStepBlockList = (props: Props) => {
    return (
        <ul>
            {props.value.map((item, i) => (
                <li key={i}>
                    <RecipeStepBlock value={item} />
                </li>
            ))}
        </ul>
    );
};
