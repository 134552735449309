import React from "react";

import { templates } from "@reactivated";

import { BlogListingCard } from "../components/BlogListingCard";
import { HeaderIndex } from "../components/HeaderIndex";
import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.BlogIndexPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            {!props.tag_filter && (
                <HeaderIndex
                    title={props.page.title}
                    introduction={props.page.introduction}
                />
            )}
            <div className="container">
                {props.tag_filter && (
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="index-header__title">Blog</h1>
                        </div>
                        <div className="col-md-12">
                            <p className="index-header__introduction">
                                Viewing all blog posts sorted by the tag{" "}
                                <span className="blog-tags__tag">
                                    {props.tag_filter}
                                </span>
                                .
                            </p>
                        </div>
                    </div>
                )}

                {props.page.all_tags && !props.tag_filter && (
                    <ul className="blog-tags">
                        <li>
                            <span className="blog-tags__pill blog-tags__pill--selected">
                                All
                            </span>
                        </li>
                        {props.page.all_tags.map((tag) => (
                            <li key={tag.slug}>
                                <a
                                    className="blog-tags__pill"
                                    aria-label={`Filter by tag name ${tag.name}`}
                                    href={tag.url}
                                >
                                    {tag.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                )}

                <div className="blog-list">
                    {props.posts.map((post) => (
                        <BlogListingCard key={post.id} {...post} />
                    ))}
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
