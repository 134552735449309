import React from "react";

import { ImageBlock as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

interface Props {
    value: Value;
}

export const ImageBlock = (props: Props) => {
    if (!props.value.image) {
        return null;
    }
    return (
        <figure>
            <ImageChooserBlock value={props.value.image} />
            <figcaption>
                {props.value.caption} - {props.value.attribution}
            </figcaption>
        </figure>
    );
};
