import React from "react";

import { templates } from "@reactivated";

import { BaseToolbox } from "../components/BaseToolbox";
import { HeaderHero } from "../components/HeaderHero";
import { PageSkeleton } from "../components/PageSkeleton";

const Ingredients = (props: {
    ingredients: templates.BreadPageTemplate["page"]["ingredients"];
}) => (
    <>
        <h4>Ingredients</h4>
        <ul>
            {props.ingredients.map((ingredient) => (
                <li key={ingredient.name}>{ingredient.name}</li>
            ))}
        </ul>
    </>
);

export const Template = (props: templates.BreadPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <HeaderHero {...props.page} />
            <div className="container bread-detail">
                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-7">
                            <div className="row">
                                {props.page.introduction && (
                                    <p className="bread-detail__introduction">
                                        {props.page.introduction}
                                    </p>
                                )}

                                <div className="hidden-md-down">
                                    <BaseToolbox
                                        value={props.page.body.value}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-md-offset-1">
                            <div className="row">
                                <div className="bread-detail__meta">
                                    {props.page.origin && (
                                        <>
                                            <p className="bread-detail__meta-title">
                                                Origin
                                            </p>
                                            <p className="bread-detail__meta-content">
                                                {props.page.origin.title}
                                            </p>
                                        </>
                                    )}
                                    {props.page.bread_type && (
                                        <>
                                            <p className="bread-detail__meta-title">
                                                Type
                                            </p>
                                            <p className="bread-detail__meta-content">
                                                {props.page.bread_type.title}
                                            </p>
                                        </>
                                    )}
                                    {props.page.ingredients.length > 0 && (
                                        <Ingredients
                                            ingredients={props.page.ingredients}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className="row hidden-md-up">
                                <BaseToolbox value={props.page.body.value} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
