import React from "react";

import { HeadingBlock as Value } from "@reactivated";

interface Props {
    value: Value;
}

export const HeadingBlock = (props: Props) => {
    const size = props.value.size || "h2";
    return React.createElement(size, {}, [props.value.heading_text]);
};
