import React from "react";
import { Helmet } from "react-helmet-async";

import {
    ServerCmsModelsPagesUtilsBreadcrumb as Breadcrumb,
    Context,
} from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Breadcrumbs } from "../Breadcrumbs";
import { GlobalFooter } from "../GlobalFooter";
import { GlobalHeader } from "../GlobalHeader";

import styles from "./index.module.scss";

interface Props {
    title: string;
    seo_title?: string;
    search_description?: string;
    robots?: string;
    rootClassName?: string;
    mainClassName?: string;
    breadcrumbs?: Breadcrumb[];
    children?: React.ReactNode;
}

export const PageSkeleton = (props: Props) => {
    const context = React.useContext(Context);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.seo_title || props.title}</title>
                {props.search_description && (
                    <meta
                        name="description"
                        content={props.search_description}
                    />
                )}
                {props.robots && <meta name="robots" content={props.robots} />}
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link
                    rel="icon"
                    href={context.core_assets.favicon}
                    type="image/x-icon"
                />
            </Helmet>
            <div className={concatClassNames([props.rootClassName])}>
                <GlobalHeader />
                {props.breadcrumbs && (
                    <Breadcrumbs breadcrumbs={props.breadcrumbs} />
                )}
                {props.children && (
                    <main
                        className={concatClassNames([
                            styles.main,
                            props.mainClassName,
                        ])}
                    >
                        {props.children}
                    </main>
                )}
                <hr />
                <GlobalFooter />
            </div>
        </>
    );
};
