import React from "react";

import { ServerCmsModelsPagesHomePageFeaturedPageTemplate as FeaturedPage } from "@reactivated";

import { ImageRenditionSet } from "@thelabnyc/thelabui/src/components/ImageRenditionSet";

interface Props {
    page: FeaturedPage;
}

export default function ListingCard({ page }: Props) {
    return (
        <div className="listing-card">
            <a className="listing-card__link" href={page.url}>
                {page.image && (
                    <figure className="listing-card__image">
                        <ImageRenditionSet renditions={[page.image]} />
                    </figure>
                )}
                <div className="listing-card__contents">
                    <h3 className="listing-card__title">{page.title}</h3>
                    {(page.origin || page.bread_type) && (
                        <table className="listing-card__meta">
                            {page.origin && (
                                <tr>
                                    <td className="listing-card__meta-category">
                                        Origin
                                    </td>
                                    <td className="listing-card__meta-content">
                                        {page.origin}
                                    </td>
                                </tr>
                            )}
                            {page.bread_type && (
                                <tr>
                                    <td className="listing-card__meta-category">
                                        Type
                                    </td>
                                    <td className="listing-card__meta-content">
                                        {page.bread_type}
                                    </td>
                                </tr>
                            )}
                        </table>
                    )}
                </div>
            </a>
        </div>
    );
}
