import React from "react";

import { FieldHandler, Widget } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

export const FormField = (props: {
    wrapperClassName?: string;
    field: FieldHandler;
}) => {
    const field = props.field;
    return (
        <div
            className={concatClassNames([
                "form-page__field",
                field.error ? "error" : null,
                props.wrapperClassName,
            ])}
        >
            <label htmlFor={field.widget.attrs.id}>{field.label}</label>
            {field.widget.required && <span className="required">*</span>}
            <Widget field={field} />
            {field.error != null && <div className="error">{field.error}</div>}
        </div>
    );
};
