import React from "react";

import { templates } from "@reactivated";

import { ImageRenditionSet } from "@thelabnyc/thelabui/src/components/ImageRenditionSet";

import { HeaderHero } from "../components/HeaderHero";
import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.GalleryPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <HeaderHero {...props.page} />
            <div className="container gallery__container">
                <div className="row">
                    <div className="col-md-8">
                        {props.page.introduction && (
                            <p className="gallery__introduction">
                                {props.page.introduction}
                            </p>
                        )}
                    </div>
                </div>
                <div className="gallery__grid">
                    {props.page.gallery_images.map((tile, i) => {
                        return (
                            <React.Fragment key={i}>
                                <div className="picture-card">
                                    <figure className="picture-card__image">
                                        <ImageRenditionSet
                                            renditions={tile.renditions_tile_lg}
                                        />
                                        <div className="picture-card__contents">
                                            <p className="picture-card__title">
                                                {tile.title}
                                            </p>
                                        </div>
                                    </figure>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
