import React from "react";

import { templates } from "@reactivated";

import { ImageRenditionSet } from "@thelabnyc/thelabui/src/components/ImageRenditionSet";

import { HeaderIndex } from "../components/HeaderIndex";
import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.LocationsIndexPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <HeaderIndex {...props.page} />
            <div className="container">
                <div className="location-list-page">
                    {props.page.locations.map((location) => (
                        <div className="picture-card" key={location.id}>
                            <a
                                className="picture-card__link"
                                href={location.url}
                            >
                                <figure className="picture-card__image">
                                    {location.image && (
                                        <ImageRenditionSet
                                            renditions={
                                                location.image
                                                    .renditions_tile_lg
                                            }
                                        />
                                    )}
                                    <div className="picture-card__contents">
                                        <h3 className="picture-card__title">
                                            {location.title}
                                        </h3>
                                    </div>
                                </figure>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
