import React from "react";

import {
    CmsModelsPagesBlogIndexPageBlogPostCardTemplate as BlogPostCardTemplate,
    CmsModelsPagesRecipeIndexPageRecipeCardTemplate as RecipeCardTemplate,
} from "@reactivated";

import { ImageRenditionSet } from "@thelabnyc/thelabui/src/components/ImageRenditionSet";

type Props = RecipeCardTemplate | BlogPostCardTemplate;

const isBlogPost = (post: Props): post is BlogPostCardTemplate => {
    /* eslint-disable-next-line:@typescript-eslint/no-unnecessary-condition */
    return (post as BlogPostCardTemplate).image !== undefined;
};

export const BlogListingCard = (props: Props) => {
    return (
        <div className="blog-listing-card">
            <a className="blog-listing-card__link" href={props.url}>
                {isBlogPost(props) && props.image && (
                    <figure className="blog-listing-card__image">
                        <ImageRenditionSet
                            renditions={props.image.renditions_tile_sm}
                        />
                    </figure>
                )}
                <div className="blog-listing-card__contents">
                    <h2 className="blog-listing-card__title">{props.title}</h2>
                    {props.introduction && (
                        <p className="blog-listing-card__introduction">
                            {props.introduction}
                        </p>
                    )}
                    <p className="blog-listing-card__metadata">
                        {props.date_published && (
                            <>{props.date_published} by&nbsp;</>
                        )}
                        {props.post_authors.map((author, i) => (
                            <React.Fragment key={i}>
                                {author.first_name} {author.last_name}
                                {i + 1 < props.post_authors.length && ", "}
                            </React.Fragment>
                        ))}
                    </p>
                </div>
            </a>
        </div>
    );
};
