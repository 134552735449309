import React from "react";

import { templates } from "@reactivated";

import { ImageRenditionSet } from "@thelabnyc/thelabui/src/components/ImageRenditionSet";

import { HeaderIndex } from "../components/HeaderIndex";
import { PageSkeleton } from "../components/PageSkeleton";

const BreadCard = (
    props: templates.BreadsIndexPageTemplate["page"]["breads"][number],
) => (
    <li>
        <div className="listing-card">
            <a className="listing-card__link" href={props.url}>
                {props.image && (
                    <figure className="listing-card__image">
                        <ImageRenditionSet
                            renditions={props.image.renditions_tile_sm}
                        />
                    </figure>
                )}
                <div className="listing-card__contents">
                    <h3 className="listing-card__title">{props.title}</h3>
                    {(props.origin || props.bread_type) && (
                        <table className="listing-card__meta">
                            {props.origin && (
                                <tr>
                                    <td className="listing-card__meta-category">
                                        Origin
                                    </td>
                                    <td className="listing-card__meta-content">
                                        {props.origin.title}
                                    </td>
                                </tr>
                            )}
                            {props.bread_type && (
                                <tr>
                                    <td className="listing-card__meta-category">
                                        Type
                                    </td>
                                    <td className="listing-card__meta-content">
                                        {props.bread_type.title}
                                    </td>
                                </tr>
                            )}
                        </table>
                    )}
                </div>
            </a>
        </div>
    </li>
);

export const Template = (props: templates.BreadsIndexPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <HeaderIndex {...props.page} />
            <div className="container">
                <ul className="bread-list">
                    {props.page.breads.map((bread) => (
                        <BreadCard key={bread.url} {...bread} />
                    ))}
                </ul>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
