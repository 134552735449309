import React from "react";

import { templates } from "@reactivated";

import { RichText } from "@thelabnyc/thelabui/src/components/RichText";

import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.FormPageLandingPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <div className="container form-page-thanks">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="index-header__title">
                            {props.page.title}
                        </h1>
                    </div>
                    <div className="col-md-7 index-header__body-introduction">
                        <RichText value={props.page.thank_you_text} />
                    </div>
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
