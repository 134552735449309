import React from "react";

import { WagtailImageRendition } from "@reactivated";

interface Props {
    image: {
        renditions_hero: WagtailImageRendition[];
    } | null;
    title: string;
}

export const HeaderHero = (props: Props) => {
    if (props.image) {
        const bestRend =
            props.image.renditions_hero[props.image.renditions_hero.length - 1];
        return (
            <div
                className="container-fluid hero"
                style={{
                    backgroundImage: `url(${bestRend.url})`,
                }}
            >
                <div className="hero__container">
                    <h1 className="hero__title">{props.title}</h1>
                </div>
            </div>
        );
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-7">
                    <h1>{props.title}</h1>
                </div>
            </div>
        </div>
    );
};
