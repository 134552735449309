import React from "react";

import { RecipeStepBlock as Value } from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

interface Props {
    value: Value;
}

export const RecipeStepBlock = (props: Props) => {
    if (!props.value.text) {
        return null;
    }
    return <RichTextBlock value={props.value.text} />;
};
