import React from "react";

import { ServerCmsModelsPagesUtilsBreadcrumb as BreadcrumbValue } from "@reactivated";

import Chevron from "../ChevronIcon";

const Breadcrumb = (props: {
    page: BreadcrumbValue;
    index: number;
    total: number;
}) => {
    if (props.index == props.total - 1) {
        return <li aria-current="page">{props.page.title}</li>;
    }
    return (
        <li>
            <a href={props.page.url}>
                {props.index === 0 ? "Home" : props.page.title}
            </a>
            <Chevron className="breadcrumb__chevron-icon" />
        </li>
    );
};

export const Breadcrumbs = (props: { breadcrumbs: BreadcrumbValue[] }) => (
    <nav className="breadcrumb-container" aria-label="Breadcrumb">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <ol className="breadcrumb">
                        {props.breadcrumbs.map((page, i) => (
                            <Breadcrumb
                                key={page.pk}
                                page={page}
                                index={i}
                                total={props.breadcrumbs.length}
                            />
                        ))}
                    </ol>
                </div>
            </div>
        </div>
    </nav>
);
