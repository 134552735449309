import React from "react";

import { CmsModelsSnippetsBasePersonTemplate } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

export const BlogAuthor = ({
    author,
}: {
    author: CmsModelsSnippetsBasePersonTemplate;
}) => (
    <div key={author.pk} className="blog__author">
        {author.image && (
            <ImageChooserBlock
                value={author.image.block}
                renditionNames={new Set(["thumbnail"])}
                imageAttrs={{
                    className: "blog__avatar",
                    style: {
                        width: 50,
                        height: 50,
                    },
                }}
            />
        )}
        {author.first_name} {author.last_name}
    </div>
);
