import React from "react";

import { Props, SvgElement } from "@thelabnyc/thelabui/src/components/Svg";

export default function ChevronIcon(props: Omit<Props<string>, "name">) {
    return (
        <SvgElement
            {...props}
            aria-hidden="true"
            viewBox="0 0 11 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.41669 23.2091L7.76243 17L11 17L4.13954 25L0.302327 25C0.453036 24.9651 0.617043 24.8867 0.794348 24.7647C0.971652 24.6427 1.15339 24.4989 1.33956 24.3333C1.52573 24.1678 1.7119 23.9891 1.89807 23.7974C2.08424 23.5969 2.25711 23.4009 2.41669 23.2091Z"
                fill="#C55302"
            />
            <path
                d="M2.41669 10.7908L7.76243 17L11 17L4.13954 9L0.302327 9C0.453036 9.03486 0.617043 9.11329 0.794348 9.23529C0.971652 9.3573 1.15339 9.50109 1.33956 9.66667C1.52573 9.83224 1.7119 10.0109 1.89807 10.2026C2.08424 10.403 2.25711 10.5991 2.41669 10.7908Z"
                fill="#C55302"
            />
        </SvgElement>
    );
}
