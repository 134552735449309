import React from "react";

import { WagtailImageRendition } from "@reactivated";

interface Props {
    image: {
        renditions_hero: WagtailImageRendition[];
    } | null;
    title: string;
    subtitle?: string;
    introduction?: string;
    date_published?: string | null;
}

export const HeaderBlog = (props: Props) => {
    const bestRend = props.image
        ? props.image.renditions_hero[props.image.renditions_hero.length - 1]
        : null;
    return (
        <>
            {bestRend && (
                <div
                    className="container-fluid hero hero--blog"
                    style={{
                        backgroundImage: `url('${bestRend.url}')`,
                    }}
                ></div>
            )}
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-9">
                        <h1 className="index-header__title index-header__title--blog">
                            {props.title}
                        </h1>
                        {props.subtitle && <p>{props.subtitle}</p>}
                    </div>
                    <div className="col-sm-12 col-md-7">
                        {props.introduction && (
                            <p className="index-header__introduction index-header__introduction--blog">
                                {props.introduction}
                            </p>
                        )}
                        {props.date_published && (
                            <div className="blog__published">
                                {props.date_published}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
