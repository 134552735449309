import React from "react";

import { templates } from "@reactivated";

import { BaseToolbox } from "../components/BaseToolbox";
import { ContactForm } from "../components/ContactForm";
import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.FormPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="index-header__title">
                            {props.page.title}
                        </h1>
                    </div>
                    <div className="col-md-8 index-header__body-introduction">
                        <BaseToolbox value={props.page.body.value} />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-8 form-page">
                        <ContactForm form={props.form} />
                    </div>
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
