import React from "react";

import {
    CSRFToken,
    ServerCmsModelsPagesFormPage,
    Widget,
    useForm,
} from "@reactivated";

import { FormField } from "../FormField";

interface IProps {
    form: ServerCmsModelsPagesFormPage;
}

type FormFieldName = keyof ServerCmsModelsPagesFormPage["fields"];

type FieldRow = FormFieldName[];
type FieldRows = FieldRow[];

export const ContactForm = (props: IProps) => {
    const fieldRows: FieldRows = [["subject"], ["name"], ["email"], ["body"]];
    const form = useForm({
        form: props.form,
    });
    return (
        <form method="POST">
            <CSRFToken />
            {form.nonFieldErrors?.map((error, index) => (
                <div key={index} className="error">
                    {error}
                </div>
            ))}
            {form.hiddenFields.map((field, index) => (
                <Widget key={index} field={field} />
            ))}
            {fieldRows.map((row, index) => (
                <div key={index} className="field-row">
                    {row.map((fieldName) => (
                        <FormField
                            key={fieldName}
                            field={form.fields[fieldName]}
                        />
                    ))}
                </div>
            ))}
            <input type="submit" />
        </form>
    );
};
