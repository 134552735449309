import React from "react";

import { RecipeToolbox as Value } from "@reactivated";

import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";
import { TableBlock } from "@thelabnyc/thelabui/src/components/TableBlock";

import { components as baseComponents } from "../BaseToolbox";
import { RecipeIngredientsList } from "../RecipeIngredientsList";
import { RecipeStepBlockList } from "../RecipeStepBlockList";

interface Props {
    value: Value;
}

const components: StreamFieldBlockMap<Value> = {
    ...baseComponents,
    table_block: (props) => (
        <TableBlock className="block-table_block" {...props} />
    ),
    ingredients_list: RecipeIngredientsList,
    steps_list: RecipeStepBlockList,
};

export const RecipeToolbox = (props: Props) => {
    return <StreamField components={components} value={props.value} />;
};
