import React from "react";

import { BaseToolbox as Value } from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";

import { BlockQuote } from "../BlockQuote";
import { EmbedBlock } from "../EmbedBlock";
import { HeadingBlock } from "../HeadingBlock";
import { ImageBlock } from "../ImageBlock";

interface Props {
    value: Value;
}

export const components: StreamFieldBlockMap<Value> = {
    block_quote: BlockQuote,
    embed_block: EmbedBlock,
    heading_block: HeadingBlock,
    image_block: ImageBlock,
    paragraph_block: RichTextBlock,
};

export const BaseToolbox = (props: Props) => {
    return <StreamField components={components} value={props.value} />;
};
