import React from "react";

import { BlockQuote as Value } from "@reactivated";

interface Props {
    value: Value;
}

export const BlockQuote = (props: Props) => {
    return (
        <blockquote>
            <p className="text">{props.value.text}</p>
            <p className="attribute-name">{props.value.attribute_name}</p>
        </blockquote>
    );
};
