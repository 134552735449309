import React from "react";

import { EmbedBlock as Value } from "@reactivated";

interface Props {
    value: Value;
}

export const EmbedBlock = (props: Props) => {
    const embedProps = {
        className: "block-embed_block",
        dangerouslySetInnerHTML: {
            __html: props.value,
        },
    };
    return <div {...embedProps} />;
};
