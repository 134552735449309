import React from "react";

import { templates } from "@reactivated";

import { BaseToolbox } from "../components/BaseToolbox";
import { BlogAuthor } from "../components/BlogAuthor";
import { HeaderBlog } from "../components/HeaderBlog";
import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.BlogPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <HeaderBlog {...props.page} />
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="blog__meta">
                            {props.page.post_authors.length > 0 && (
                                <div className="blog__avatars">
                                    {props.page.post_authors.map((author) => (
                                        <BlogAuthor
                                            key={author.pk}
                                            author={author}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                        <BaseToolbox value={props.page.body.value} />

                        {props.page.post_tags.length > 0 && (
                            <>
                                <p className="blog__tag-introduction">
                                    Find more blog posts with similar tags
                                </p>
                                <div className="blog-tags blog-tags--condensed">
                                    <span className="u-sr-only">
                                        Filter blog posts by tag
                                    </span>
                                    {props.page.post_tags.map((tag) => (
                                        <a
                                            key={tag.slug}
                                            href={tag.url}
                                            className="blog-tags__pill"
                                        >
                                            {tag.name}
                                        </a>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
