import React from "react";

interface Props {
    title: string;
    introduction?: string;
}

export const HeaderIndex = (props: Props) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="index-header__title">{props.title}</h1>
                </div>
                <div className="col-sm-12 col-md-7">
                    {props.introduction && (
                        <p className="index-header__introduction">
                            {props.introduction}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};
