import React from "react";

import { templates } from "@reactivated";

import { RichText } from "@thelabnyc/thelabui/src/components/RichText";

import { BaseToolbox } from "../components/BaseToolbox";
import { BlogAuthor } from "../components/BlogAuthor";
import { HeaderBlog } from "../components/HeaderBlog";
import { PageSkeleton } from "../components/PageSkeleton";
import { RecipeToolbox } from "../components/RecipeToolbox";

export const Template = (props: templates.RecipePageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} breadcrumbs={props.breadcrumbs}>
            <HeaderBlog {...props.page} image={null} />
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="blog__meta">
                            {props.page.post_authors.length > 0 && (
                                <div className="blog__avatars">
                                    {props.page.post_authors.map((author) => (
                                        <BlogAuthor
                                            key={author.pk}
                                            author={author}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>

                        {props.page.backstory && (
                            <>
                                <BaseToolbox
                                    value={props.page.backstory.value}
                                />
                                <hr />
                            </>
                        )}

                        <div id="recipe-headline" className="sr-only">
                            Recipe
                            {props.page.recipe_headline && (
                                <>
                                    :&nbsp;
                                    <RichText
                                        value={props.page.recipe_headline}
                                    />
                                </>
                            )}
                        </div>

                        <section aria-labelledby="recipe-headline">
                            <RecipeToolbox value={props.page.body.value} />
                        </section>
                    </div>
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
