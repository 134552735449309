import { BaseToolbox } from "@client/components/BaseToolbox";
import ChevronIcon from "@client/components/ChevronIcon";
import ListingCard from "@client/components/ListingCard";
import React from "react";

import { templates } from "@reactivated";

import { ImageRenditionSet } from "@thelabnyc/thelabui/src/components/ImageRenditionSet";
import { RichText } from "@thelabnyc/thelabui/src/components/RichText";

import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.HomePageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} rootClassName="homepage">
            <div
                className="container-fluid hero"
                style={{
                    backgroundImage: props.page.image
                        ? `url(${props.page.image.block.url})`
                        : undefined,
                }}
            >
                <div className="hero-gradient-mask"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-1 col-lg-5 home-hero">
                            <h1>{props.page.title}</h1>
                            <p className="lead">{props.page.hero_text}</p>
                            {props.page.hero_cta_link ? (
                                <a
                                    href={props.page.hero_cta_link.url}
                                    className="hero-cta-link"
                                >
                                    {props.page.hero_cta}
                                </a>
                            ) : (
                                props.page.hero_cta
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row promo-row">
                    <div className="featured-cards col-sm-5 col-sm-offset-1">
                        {props.page.featured_section_1_data && (
                            <>
                                <h2 className="featured-cards__title">
                                    {props.page.featured_section_1_title}
                                </h2>
                                <ul className="featured-cards__list">
                                    {props.page.featured_section_1_data.map(
                                        (childpage) => (
                                            <li key={childpage.id}>
                                                <ListingCard page={childpage} />
                                            </li>
                                        ),
                                    )}
                                </ul>
                                <a
                                    className="featured-cards__link"
                                    href="/breads"
                                >
                                    <span>View more of our breads</span>
                                    <ChevronIcon className="featured-cards__chevron-icon" />
                                </a>
                            </>
                        )}
                    </div>

                    <div className="col-sm-6 promo">
                        {(props.page.promo_image ||
                            props.page.promo_title ||
                            props.page.promo_text) && (
                            <>
                                <div className="col-lg-10 promo-text">
                                    {props.page.promo_title && (
                                        <h2>{props.page.promo_title}</h2>
                                    )}
                                    {props.page.promo_text && (
                                        <RichText
                                            value={props.page.promo_text}
                                        />
                                    )}
                                </div>
                                {props.page.promo_image && (
                                    <figure>
                                        <ImageRenditionSet
                                            renditions={
                                                props.page.promo_image
                                                    .renditions_promo
                                            }
                                        />
                                    </figure>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {props.page.body && (
                <div className="container-fluid streamfield">
                    <div className="row">
                        <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 streamfield-column">
                            <BaseToolbox value={props.page.body.value} />
                        </div>
                    </div>
                </div>
            )}
        </PageSkeleton>
    </React.StrictMode>
);
