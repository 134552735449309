import React from "react";

import { RichTextBlockList } from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

interface Props {
    value: RichTextBlockList;
}

export const RecipeIngredientsList = (props: Props) => {
    return (
        <ul>
            {props.value.map((item, i) => (
                <li key={i}>
                    <RichTextBlock value={item} />
                </li>
            ))}
        </ul>
    );
};
